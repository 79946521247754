<template>
  <div class="OperatingInstructions">
    <!-- <PublicTitle @back-call="backPath" :title="pageTitle" /> -->
    <div class="hearderBox">
            <span @click="backPath">
              <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span> 
        {{pageTitle}}
        </div>
    <div class="OperatingInstructionsContent">
      <Field :fieldList="fieldList" @call-back="fieldCall"></Field>

      <Skeleton v-if="isFirst" :skList="skList"></Skeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperatingInstructions",
  components: {},
  data() {
    return {
      isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
      navBackgroundColor: {
        lilly: "#D7290F",
        roche: "#2e82ef",
        novartis: "#0099FF",
        pfizer: "#E60012",
        ipsen: "#224a81",
        msd: "#21BDB0",
        dsm: "#00A16D",
        dscl: "#00b4ed",
      },
      pageTitle:'APP操作说明',
      isFirst: true,
      fieldList: [],
      skList: [
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
      ],
    };
  },
  computed: {
    // 会引擎
    needTenantId() {
        let tenant = this.$cookies.get("tenant") || '';
        return tenant == 'crossevent'
    },
  },
  created() {
    this.pageTitle=this.$cookies.get('tenant')=='msd'?'操作说明':'APP操作说明'
    this.getCustomerServiceInfo();
  },
  methods: {
    async getCustomerServiceInfo() {
      let tenant = this.$cookies.get("tenant") || this.$iStorage.get("tenant");
      const params = {
        collection: "cfg-ihelper-operating-instructions",
        filter: {
          tenantCode: tenant,
        },
      };
      // 会引擎
      if (this.needTenantId) {
        params.filter.tenantId = this.$cookies.get("tenant_id");
      }
      let _this = this;
      this.services.GetSettings(params).then(function (res) {
        if (res.success) {
          _this.isFirst = false;
          _this.fieldList = res.content.options;
        }
      });
    },

    fieldCall(data) {
      let tenant = this.$cookies.get("tenant");
      let container =
        this.$cookies.get("container") || sessionStorage.getItem("container");
      if (data.callBack == "trainingMaterials") {
        switch (container) {
          case "browser":
          case "h5":
            // 直接下载
            console.log("====文件信息", data);
            window.open(data.path);
            break;
          case "native":
          default:
            if (window.flutter_inappwebview) {
              window.flutter_inappwebview.callHandler(
                "App.openPdf",data.path
              );
            } else {
              this.iJsBridge.call({
                method: "SMGeneral.openNewWebSite",
                postData: {
                  loadUrl: data.path,
                  browserMode: {
                    navBarVisibilty: true,
                    titleTextColor: "#ffffff",
                    navBackgroundColor: this.navBackgroundColor[tenant],
                  },
                },
              });
            }

            break;
        }
      }
      // switch (data.callBack) {
      //   case "trainingMaterials": //培训资料
      //     this.iJsBridge.call({
      //       method: "SMGeneral.openNewWebSite",
      //       postData: {
      //         loadUrl: data.path,
      //         browserMode: {
      //           navBarVisibilty: true,
      //           titleTextColor: "#ffffff",
      //           navBackgroundColor: this.navBackgroundColor[tenant],
      //         },
      //       },
      //     });
      //     // this.$router.push({
      //     //     name: 'PDFViewer',
      //     //     params: {
      //     //         itemInfo: data
      //     //     }
      //     // })
      //     break;
      //   default:
      //     break;
      // }
    },
    backPath() {
      this.iJsBridge.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.OperatingInstructions {
  // padding-top: 1rem;
  .OperatingInstructionsContent {
    padding: 0.26rem;
  }
}
</style>
